<template>
    <date-picker
        :input-class="inputClass"
        :class="wrapperClass"
        :popup-class="popupClass"
        :append-to-body="appendToBody"
        format="DD.MM.YYYY"
        v-model:value="valInput"
        type="date"
        @change="change"

        :placeholder="placeholder"
        :input-attr="{name: name, id: id}"
    >
        <template v-if="floating" #input="props">
            <div class="form-floating">
                <input type="text"
                       :id="props.id"
                       :name="props.name"
                       v-model="props.value"
                       class="form-control"
                       :placeholder="props.placeholder"
                       autocomplete="off"
                       :class="inputClass"
                       @input="props.onInput"
                       @change="props.onChange"
                       @focus="props.onFocus"
                       @keydown="props.onKeydown"
                >
                <label :for="props.id">{{ label }}</label>
            </div>
        </template>
    </date-picker>
</template>

<script>
    import DatePicker from 'vue-datepicker-next';
    import {parseDate} from "@/composables/parseDateTime.js";
    import dayjs from "dayjs";
    import customParseFormat from "dayjs/plugin/customParseFormat";
    dayjs.extend(customParseFormat);

    export default {
        components: {
            DatePicker
        },
        props: {
            placeholder: {
                type: String,
                default: ''
            },
            name: {
                type: String,
                default: ''
            },
            label: {
                type: String,
                default: ''
            },
            appendToBody: {
                type: Boolean,
                default: true
            },
            floating: {
                type: String,
                default: ''
            },
            id: {
                type: String,
                default: ''
            },
            inputClass: {
                type: String,
                default: 'form-control'
            },
            wrapperClass: {
                type: String,
                default: ''
            },
            popupClass: {
                type: String,
                default: ''
            },
            old: {
                default: null
            },
            oldFormat: {
                type: String,
                default: 'dd.mm.yyyy'
            },
            val: {
                type: String,
                default: null
            },
            valFormat: {
                type: String,
                default: 'dd.mm.yyyy'
            },
            modelValue: {
                default: null
            },
            returnFormat: {
                type: String,
                default: null
            }
        },

        data () {
            return {
                valInput: ''
            }
        },
        mounted () {
            if (this.old !== null && this.old.length) {
                this.valInput = parseDate(this.old, this.oldFormat)
            } else {
                if (this.modelValue !== null && this.modelValue !== '') {
                    this.valInput = parseDate(this.modelValue, this.valFormat)
                }
                else if (this.val !== null) {
                    this.valInput = parseDate(this.val, this.valFormat)
                }
            }
        },
        methods: {
            change(val) {
                this.$emit('change');
                if (this.returnFormat) {
                    this.$emit('update:modelValue', dayjs(this.valInput).format(this.returnFormat))
                } else {
                    this.$emit('update:modelValue', this.valInput)
                }
            },
        },
        watch: {
            modelValue(val) {
                if (typeof val === 'string') {
                    val = parseDate(val, this.valFormat)
                }
                this.valInput = val
            },
            input(val) {
                this.$emit('update:modelValue', this.valInput)
            }
        }
    }
</script>
